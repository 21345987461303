import { MdLock } from 'react-icons/md';

const SidebarWrapper = ({ children }) => {
    return (
        <div className="lg:col-span-1">
            <div className="lg:hidden h-20 flex items-center justify-start">
                <img
                    src="/logo.png"
                    alt="Rechtspsychologische Praxis Hoffmann"
                    className="h-12"
                />
            </div>
            <div className="hidden lg:block border-r border-neutral-100 bg-white">
                <div className="min-h-screen flex flex-col w-5/6 mx-auto">
                    <div className="h-24 flex items-center justify-center">
                        <img
                            src="/logo.png"
                            alt="Rechtspsychologische Praxis Hoffmann"
                            className="h-12 w-auto"
                        />
                    </div>
                    <div className="flex-grow">
                        <h1 className="text-lg font-semibold mb-8">Auftragsformular</h1>
                        <nav>
                            <ul className="flex flex-col gap-y-8">

                                {children}

                            </ul>
                        </nav>
                    </div>
                    <div className="h-10 flex items-center justify-center gap-x-4">
                        <a
                            href="https://rp-hoffmann.de/impressum"
                            target="_blank"
                            className="text-sm text-neutral-400 hover:text-red transition-all"
                        >
                            Impressum
                        </a>
                        <a
                            href="https://rp-hoffmann.de/datenschutz"
                            target="_blank"
                            className="text-sm text-neutral-400 hover:text-red transition-all"
                        >
                            Datenschutz
                        </a>
                    </div>
                    <div className="h-24 flex items-center justify-center border-t border-neutral-200">
                        <div className="flex items-center gap-x-1">
                            <MdLock className="h-8 w-8 fill-red" />
                            <div className="text-xs leading-4">SSL-geschützte<br /> Datenübertragung</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidebarWrapper