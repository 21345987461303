const navs = [
    {
        num: 1,
        label: 'Fragestellung'
    },
    {
        num: 2,
        label: 'Auftraggeber'
    },
    {
        num: 3,
        label: 'Fallbeschreibung'
    },
    {
        num: 4,
        label: 'Kontaktdaten'
    }
];

const Sidebar = ({ step }) => {
    return (
        <>
            {navs.map((nav) =>
                <li className="flex items-center gap-x-2" key={nav.num}>
                    {step >= nav.num &&
                        <>
                            <div className="h-6 w-6 flex items-center justify-center bg-red text-xs font-semibold text-white border-2 border-red rounded-full">{nav.num}</div>
                            <div className="text-sm font-semibold">{nav.label}</div>
                        </>
                    }
                    {step < nav.num &&
                        <>
                            <div className="h-6 w-6 flex items-center justify-center bg-white text-xs font-semibold text-neutral-400 border-2 border-neutral-300 rounded-full">{nav.num}</div>
                            <div className="text-sm font-semibold text-neutral-400">{nav.label}</div>
                        </>
                    }
                </li>
            )}
        </>
    )
}

export default Sidebar