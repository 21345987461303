import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auftraggeber from './Schritte/Auftraggeber';
import Fallbeschreibung from './Schritte/Fallbeschreibung';
import Fragestellung from './Schritte/Fragestellung';
import Kontaktdaten from './Schritte/Kontaktdaten';
import VielenDank from './Schritte/VielenDank';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/*" element={<Fragestellung />} />
            <Route path="/auftraggeber" element={<Auftraggeber />} />
            <Route path="/fallbeschreibung" element={<Fallbeschreibung />} />
            <Route path="/kontaktdaten" element={<Kontaktdaten />} />
            <Route path="/vielen-dank" element={<VielenDank />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
