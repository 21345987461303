import { MdPhone } from 'react-icons/md';

const FormWrapper = ({ children, frage }) => {
  return (
    <div className="lg:col-span-3 xl:col-span-4 bg-neutral-50">
        <div className="hidden h-20 lg:flex items-center justify-start lg:justify-end lg:w-11/12 mx-auto gap-x-8">
          <div className="flex items-center gap-x-1.5 lg:text-sm font-semibold">
            <MdPhone className="h-4 w-4 fill-red" />
            <span>02235 987 4791</span>
          </div>
        </div>
        <div className="lg:w-5/6 xl:w-2/3 mx-auto">
            <h2 className="text-2xl lg:text-3xl font-bold mt-6 mb-8 lg:mb-12">{frage}</h2>
            {children}
        </div>
    </div>
  )
}

export default FormWrapper