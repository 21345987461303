import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../Layout/FormWrapper';
import Layout from '../Layout/Layout';
import Sidebar from '../Layout/Sidebar';
import SidebarWrapper from '../Layout/SidebarWrapper';
import { chooseFragestellung } from '../rootSlice'; 
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { useState } from 'react';

const Fragestellung = () => {
    const antworten = [
        {
            label: 'Strafsache',
            value: 'strafsache',
            name: 'fragestellung',
            id: 'fragestellung1'
        },
        {
            label: 'Familiensache',
            value: 'familiensache',
            name: 'fragestellung',
            id: 'fragestellung2'
        },
        {
            label: 'Kombinierte Fragestellung',
            value: 'kombiniert',
            name: 'fragestellung',
            id: 'fragestellung3'
        }
    ];
    const [fragestellungActive, setFragestellungActive] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fragestellung = useSelector(state => state.fragestellung);
    const { register, handleSubmit } = useForm({ defaultValues: { fragestellung } })

    const onSubmit = (data) => {
        dispatch(chooseFragestellung(data.fragestellung));
        navigate('./auftraggeber');
    }

    return (
        <Layout>
            <SidebarWrapper>
                <Sidebar step={1} />
            </SidebarWrapper>
            <FormWrapper frage={'Frage Fragestellung'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                        {antworten.map((antwort) =>
                        <div className="col-span-1" key={antwort.id}>
                            <label htmlFor={antwort.id} onClick={(e) => setFragestellungActive(e.target.value)}>
                                <input
                                    type="radio"
                                    name={antwort.name}
                                    id={antwort.id}
                                    {...register("fragestellung")}
                                    value={antwort.value}
                                    className="hidden"
                                />
                                <div className={`${fragestellungActive === antwort.value && 'border-red text-red' || 'border-neutral-200'} h-24 lg:h-28 flex items-center bg-white border-2 rounded-lg hover:border-red transition-all cursor-pointer group`}>
                                    <div className="w-4/5 mx-auto flex items-center gap-x-8">
                                        <div className="flex flex-grow items-center text-xl font-semibold group-hover:text-red transition-all">
                                            {antwort.label}
                                        </div>
                                        <div className="flex items-center justify-end">
                                            <MdOutlineArrowRightAlt className={`${fragestellungActive === antwort.value && 'fill-red' || 'fill-gray'} h-8 w-8 group-hover:fill-red transition-all`} />
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        )}
                    </div>
                    {fragestellungActive !== '' &&
                        <button
                            className="bg-red hover:bg-red-hover transition-all text-lg lg:text-base text-white px-8 lg:px-6 py-3 rounded-lg mb-12"
                        >
                            Weiter
                        </button>
                    }
                </form>
            </FormWrapper>
        </Layout>
    )
}

export default Fragestellung