import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../Layout/FormWrapper';
import Layout from '../Layout/Layout';
import Sidebar from '../Layout/Sidebar';
import SidebarWrapper from '../Layout/SidebarWrapper';
import { chooseAuftraggeber } from '../rootSlice'; 
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { useState, useEffect } from 'react';

const Auftraggeber = () => {
    const fragestellung = useSelector(state => state.fragestellung);
    const [auftraggeberActive, setAuftraggeberActive] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auftraggeber = useSelector(state => state.auftraggeber);
    const { register, handleSubmit } = useForm({ defaultValues: { auftraggeber } })

    const onSubmit = (data) => {
        dispatch(chooseAuftraggeber(data.auftraggeber));
        navigate('/fallbeschreibung');
    }
    
    const antworten = [
        {
            label: 'Amtsgericht',
            value: 'amtsgericht',
            name: 'auftraggeber',
            id: 'auftraggeber1',
            strafsache: true,
            familiensache: true,
            kombiniert: true
        },
        {
            label: 'Landgericht',
            value: 'landgericht',
            name: 'auftraggeber',
            id: 'auftraggeber2',
            strafsache: true,
            familiensache: false,
            kombiniert: true
        },
        {
            label: 'Oberlandesgericht',
            value: 'oberlandesgericht',
            name: 'auftraggeber',
            id: 'auftraggeber3',
            strafsache: true,
            familiensache: true,
            kombiniert: true
        },
        {
            label: 'Staatsanwaltschaft',
            value: 'staatsanwaltschaft',
            name: 'auftraggeber',
            id: 'auftraggeber4',
            strafsache: true,
            familiensache: false,
            kombiniert: true
        },
        {
            label: 'Jugendamt',
            value: 'jugendamt',
            name: 'auftraggeber',
            id: 'auftraggeber5',
            strafsache: false,
            familiensache: true,
            kombiniert: true
        },
        {
            label: 'Einrichtung der Jugendhilfe',
            value: 'einrichtungjugendhilfe',
            name: 'auftraggeber',
            id: 'auftraggeber6',
            strafsache: false,
            familiensache: true,
            kombiniert: true
        },
        {
            label: 'Sonstige',
            value: 'sonstige',
            name: 'auftraggeber',
            id: 'auftraggeber7',
            strafsache: true,
            familiensache: true,
            kombiniert: true
        },
    ];

    useEffect(() => {
        fragestellung === '' && navigate('/');
    }, []);
    return (
        <Layout>
            <SidebarWrapper>
                <Sidebar step={2} />
            </SidebarWrapper>
            <FormWrapper frage={'Frage Auftraggeber'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fragestellung === "strafsache" &&
                        <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                            {antworten.filter((antwort) => antwort.strafsache === true).map((antwort) =>
                            <div className="col-span-1" key={antwort.id}>
                                <label htmlFor={antwort.id} onClick={(e) => setAuftraggeberActive(e.target.value)}>
                                    <input
                                        type="radio"
                                        name={antwort.name}
                                        id={antwort.id}
                                        {...register("auftraggeber")}
                                        value={antwort.value}
                                        className="hidden"
                                    />
                                    <div className={`${auftraggeberActive === antwort.value && 'border-red text-red' || 'border-neutral-200'} h-24 lg:h-28 flex items-center bg-white border-2 rounded-lg hover:border-red transition-all cursor-pointer group`}>
                                        <div className="w-4/5 mx-auto flex items-center gap-x-8">
                                            <div className="flex flex-grow items-center text-xl font-semibold group-hover:text-red transition-all">
                                                {antwort.label}
                                            </div>
                                            <div className="flex items-center justify-end">
                                                <MdOutlineArrowRightAlt className={`${auftraggeberActive === antwort.value && 'fill-red' || 'fill-gray'} h-8 w-8 group-hover:fill-red transition-all`} />
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            )}
                        </div>
                    }
                    {fragestellung === "familiensache" &&
                        <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                            {antworten.filter((antwort) => antwort.familiensache === true).map((antwort) =>
                            <div className="col-span-1" key={antwort.id}>
                                <label htmlFor={antwort.id} onClick={(e) => setAuftraggeberActive(e.target.value)}>
                                    <input
                                        type="radio"
                                        name={antwort.name}
                                        id={antwort.id}
                                        {...register("auftraggeber")}
                                        value={antwort.value}
                                        className="hidden"
                                    />
                                    <div className={`${auftraggeberActive === antwort.value && 'border-red text-red' || 'border-neutral-200'} h-24 lg:h-28 flex items-center bg-white border-2 rounded-lg hover:border-red transition-all cursor-pointer group`}>
                                        <div className="w-4/5 mx-auto flex items-center gap-x-8">
                                            <div className="flex flex-grow items-center text-xl font-semibold group-hover:text-red transition-all">
                                                {antwort.label}
                                            </div>
                                            <div className="flex items-center justify-end">
                                                <MdOutlineArrowRightAlt className={`${auftraggeberActive === antwort.value && 'fill-red' || 'fill-gray'} h-8 w-8 group-hover:fill-red transition-all`} />
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            )}
                        </div>
                    }
                    {fragestellung === "kombiniert" &&
                        <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                            {antworten.filter((antwort) => antwort.kombiniert === true).map((antwort) =>
                            <div className="col-span-1" key={antwort.id}>
                                <label htmlFor={antwort.id} onClick={(e) => setAuftraggeberActive(e.target.value)}>
                                    <input
                                        type="radio"
                                        name={antwort.name}
                                        id={antwort.id}
                                        {...register("auftraggeber")}
                                        value={antwort.value}
                                        className="hidden"
                                    />
                                    <div className={`${auftraggeberActive === antwort.value && 'border-red text-red' || 'border-neutral-200'} h-24 lg:h-28 flex items-center bg-white border-2 rounded-lg hover:border-red transition-all cursor-pointer group`}>
                                        <div className="w-4/5 mx-auto flex items-center gap-x-8">
                                            <div className="flex flex-grow items-center text-xl font-semibold group-hover:text-red transition-all">
                                                {antwort.label}
                                            </div>
                                            <div className="flex items-center justify-end">
                                                <MdOutlineArrowRightAlt className={`${auftraggeberActive === antwort.value && 'fill-red' || 'fill-gray'} h-8 w-8 group-hover:fill-red transition-all`} />
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            )}
                        </div>
                    }
                    {auftraggeberActive !== '' &&
                        <button
                            className="bg-red hover:bg-red-hover transition-all text-lg lg:text-base text-white px-8 lg:px-6 py-3 rounded-lg mb-12"
                        >
                            Weiter
                        </button>
                    }
                </form>
            </FormWrapper>
        </Layout>
    )
}

export default Auftraggeber