import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../Layout/FormWrapper';
import Layout from '../Layout/Layout';
import Sidebar from '../Layout/Sidebar';
import SidebarWrapper from '../Layout/SidebarWrapper';
import { chooseName, chooseFunktion, chooseMail, chooseTelefon } from '../rootSlice'; 
import { useState, useEffect } from 'react';

const Kontaktdaten = () => {
    const fragestellung = useSelector(state => state.fragestellung);
    const [datenschutz, setDatenschutz] = useState(false);

    console.log(datenschutz);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const name = useSelector(state => state.name);
    const funktion = useSelector(state => state.funktion);
    const email = useSelector(state => state.email);
    const telefon = useSelector(state => state.telefon);
    const { register, handleSubmit } = useForm({ defaultValues: { name, funktion, email, telefon } })



    const onSubmit = (data) => {
        dispatch(chooseName(data.name));
        dispatch(chooseFunktion(data.funktion));
        dispatch(chooseMail(data.mail));
        dispatch(chooseTelefon(data.telefon));

        navigate('/vielen-dank');
    }

    useEffect(() => {
        fragestellung === '' && navigate('/');
    }, []);
    return (
        <Layout>
            <SidebarWrapper>
                <Sidebar step={4} />
            </SidebarWrapper>
            <FormWrapper frage={'Kontaktdaten'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="name"
                                className="text-xs font-semibold"
                            >
                                Ihr Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                {...register("name")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="funktion"
                                className="text-xs font-semibold"
                            >
                                Ihre Funktion
                            </label>
                            <input
                                type="text"
                                name="funktion"
                                id="funktion"
                                {...register("funktion")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="email"
                                className="text-xs font-semibold"
                            >
                                Email
                            </label>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                {...register("mail")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="telefon"
                                className="text-xs font-semibold"
                            >
                                Telefon
                            </label>
                            <input
                                type="text"
                                name="telefon"
                                id="telefon"
                                {...register("telefon")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-1 flex gap-x-4">
                            <input
                                type="checkbox"
                                name="datenschutz"
                                id="datenschutz"
                                onChange={() => setDatenschutz(!datenschutz)}
                            />
                            <label
                                htmlFor="datenschutz"
                                className="text-xs leading-snug"
                            >
                                Ich bin damit einverstanden, dass meine Daten zur Bearbeitung meiner Anfrage verwendet werden. Die Datenschutzerklärung habe ich zur Kenntnis genommen.
                            </label>
                        </div>
                    </div>
                    {datenschutz === true &&
                        <button
                            className="bg-red hover:bg-red-hover transition-all text-lg lg:text-base text-white px-8 lg:px-6 py-3 rounded-lg mb-12"
                        >
                            Anfrage absenden
                        </button> 
                    }
                </form>
            </FormWrapper>
        </Layout>
    )
}

export default Kontaktdaten