import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import FormWrapper from '../Layout/FormWrapper';
import Layout from '../Layout/Layout';
import Sidebar from '../Layout/Sidebar';
import SidebarWrapper from '../Layout/SidebarWrapper';
import { chooseAktenzeichen, chooseBearbeitungszeitraum, chooseEilsache, chooseFallbeschreibung, chooseHaftsache } from '../rootSlice'; 
import { useState, useEffect } from 'react';

const Fallbeschreibung = () => {
    const fragestellung = useSelector(state => state.fragestellung);

    const [haftsacheActive, setHaftsacheActive] = useState('');
    const [eilsacheActive, setEilsacheActive] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const aktenzeichen = useSelector(state => state.aktenzeichen);
    const bearbeitungszeitraum = useSelector(state => state.bearbeitungszeitraum);
    const fallbeschreibung = useSelector(state => state.fallbeschreibung);
    const haftsache = useSelector(state => state.haftsache);
    const eilsache = useSelector(state => state.eilsache);
    const { register, handleSubmit } = useForm({ defaultValues: { fallbeschreibung, aktenzeichen, bearbeitungszeitraum, haftsache, eilsache } })

    const onSubmit = (data) => {
        dispatch(chooseAktenzeichen(data.aktenzeichen));
        dispatch(chooseFallbeschreibung(data.fallbeschreibung));
        dispatch(chooseBearbeitungszeitraum(data.bearbeitungszeitraum));
        dispatch(chooseHaftsache(data.haftsache));
        dispatch(chooseEilsache(data.eilsache));
        navigate('/kontaktdaten');
    }

    useEffect(() => {
        fragestellung === '' && navigate('/');
    })
    return (
        <Layout>
            <SidebarWrapper>
                <Sidebar step={3} />
            </SidebarWrapper>
            <FormWrapper frage={'Frage Fallbeschreibung'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid lg:grid-cols-2 gap-4 lg:gap-8 mb-8 lg:mb-12">
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="aktenzeichen"
                                className="text-xs font-semibold"
                            >
                                Aktenzeichen
                            </label>
                            <input
                                type="text"
                                name="aktenzeichen"
                                id="aktenzeichen"
                                {...register("aktenzeichen")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-1 flex flex-col gap-y-2">
                            <label
                                htmlFor="bearbeitungszeitraum"
                                className="text-xs font-semibold"
                            >
                                Bearbeitungszeitraum
                            </label>
                            <input
                                type="text"
                                name="bearbeitungszeitraum"
                                id="bearbeitungszeitraum"
                                {...register("bearbeitungszeitraum")}
                                className="border border-neutral-200 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        <div className="lg:col-span-2 flex flex-col gap-y-2">
                            <label
                                htmlFor="fallbeschreibung"
                                className="text-xs font-semibold"
                            >
                                Fallbeschreibung
                            </label>
                            <textarea
                                type="text"
                                name="fallbeschreibung"
                                id="fallbeschreibung"
                                {...register("fallbeschreibung")}
                                className="border border-neutral-200 h-40 py-2 px-3 rounded-lg outline-none focus:border-gray"
                            />
                        </div>
                        {fragestellung === "strafsache" &&
                            <div className="col-span-1 flex flex-col gap-y-2">
                                <label
                                    className="text-xs font-semibold"
                                >
                                    Haftsache
                                </label>
                                <div className="flex items-center gap-x-2">
                                    <label
                                        htmlFor="haftsache1"
                                        onClick={(e) => setHaftsacheActive(e.target.value)}
                                    >
                                        <input
                                            type="radio"
                                            name="haftsache"
                                            id="haftsache1"
                                            value="ja"
                                            {...register("haftsache")}
                                            className="hidden"
                                        />
                                        <div
                                            className={`${haftsacheActive === "ja" && "bg-red text-white" || haftsacheActive !== "ja" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                        >
                                            Ja
                                        </div>
                                    </label>
                                    <label
                                        htmlFor="haftsache2"
                                        onClick={(e) => setHaftsacheActive(e.target.value)}
                                    >
                                        <input
                                            type="radio"
                                            name="haftsache"
                                            id="haftsache2"
                                            value="nein"
                                            {...register("haftsache")}
                                            className="hidden"
                                        />
                                        <div
                                            className={`${haftsacheActive === "nein" && "bg-red text-white" || haftsacheActive !== "nein" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                        >
                                            Nein
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                        {fragestellung === "familiensache" &&
                            <div className="col-span-1 flex flex-col gap-y-2">
                                <label
                                    className="text-xs font-semibold"
                                >
                                    Eilsache
                                </label>
                                <div className="flex items-center gap-x-2">
                                    <label
                                        htmlFor="eilsache1"
                                        onClick={(e) => setEilsacheActive(e.target.value)}
                                    >
                                        <input
                                            type="radio"
                                            name="eilsache"
                                            id="eilsache1"
                                            value="ja"
                                            {...register("eilsache")}
                                            className="hidden"
                                        />
                                        <div
                                            className={`${eilsacheActive === "ja" && "bg-red text-white" || eilsacheActive !== "ja" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                        >
                                            Ja
                                        </div>
                                    </label>
                                    <label
                                        htmlFor="eilsache2"
                                        onClick={(e) => setEilsacheActive(e.target.value)}
                                    >
                                        <input
                                            type="radio"
                                            name="eilsache"
                                            id="eilsache2"
                                            value="nein"
                                            {...register("eilsache")}
                                            className="hidden"
                                        />
                                        <div
                                            className={`${eilsacheActive === "nein" && "bg-red text-white" || eilsacheActive !== "nein" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                        >
                                            Nein
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                        {fragestellung === "kombiniert" &&
                            <>
                                <div className="lg:col-span-1 flex flex-col gap-y-2">
                                    <label
                                        className="text-xs font-semibold"
                                    >
                                        Haftsache
                                    </label>
                                    <div className="flex items-center gap-x-2">
                                        <label
                                            htmlFor="haftsache1"
                                            onClick={(e) => setHaftsacheActive(e.target.value)}
                                        >
                                            <input
                                                type="radio"
                                                name="haftsache"
                                                id="haftsache1"
                                                value="ja"
                                                {...register("haftsache")}
                                                className="hidden"
                                            />
                                            <div
                                                className={`${haftsacheActive === "ja" && "bg-red text-white" || haftsacheActive !== "ja" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                            >
                                                Ja
                                            </div>
                                        </label>
                                        <label
                                            htmlFor="haftsache2"
                                            onClick={(e) => setHaftsacheActive(e.target.value)}
                                        >
                                            <input
                                                type="radio"
                                                name="haftsache"
                                                id="haftsache2"
                                                value="nein"
                                                {...register("haftsache")}
                                                className="hidden"
                                            />
                                            <div
                                                className={`${haftsacheActive === "nein" && "bg-red text-white" || haftsacheActive !== "nein" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                            >
                                                Nein
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="lg:col-span-1 flex flex-col gap-y-2">
                                    <label
                                        className="text-xs font-semibold"
                                    >
                                        Eilsache
                                    </label>
                                    <div className="flex items-center gap-x-2">
                                        <label
                                            htmlFor="eilsache1"
                                            onClick={(e) => setEilsacheActive(e.target.value)}
                                        >
                                            <input
                                                type="radio"
                                                name="eilsache"
                                                id="eilsache1"
                                                value="ja"
                                                {...register("eilsache")}
                                                className="hidden"
                                            />
                                            <div
                                                className={`${eilsacheActive === "ja" && "bg-red text-white" || eilsacheActive !== "ja" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                            >
                                                Ja
                                            </div>
                                        </label>
                                        <label
                                            htmlFor="eilsache2"
                                            onClick={(e) => setEilsacheActive(e.target.value)}
                                        >
                                            <input
                                                type="radio"
                                                name="eilsache"
                                                id="eilsache2"
                                                value="nein"
                                                {...register("eilsache")}
                                                className="hidden"
                                            />
                                            <div
                                                className={`${eilsacheActive === "nein" && "bg-red text-white" || eilsacheActive !== "nein" && "bg-neutral-200 hover:bg-red-hover hover:text-white transition-all"} px-8 lg:px-6 py-3 rounded-lg cursor-pointer`}
                                            >
                                                Nein
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    
                   <button
                        className="bg-red hover:bg-red-hover transition-all text-lg lg:text-base text-white px-8 lg:px-6 py-3 rounded-lg mb-12"
                    >
                        Weiter
                    </button> 
                </form>
            </FormWrapper>
        </Layout>
    )
}

export default Fallbeschreibung