import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import SidebarWrapper from '../Layout/SidebarWrapper';
import Sidebar from '../Layout/Sidebar';
import FormWrapper from '../Layout/FormWrapper';

const VielenDank = () => {
    const navigate = useNavigate();

    const fragestellung = useSelector(state => state.fragestellung);
    const auftraggeber = useSelector(state => state.auftraggeber);
    const aktenzeichen = useSelector(state => state.aktenzeichen);
    const bearbeitungszeitraum = useSelector(state => state.bearbeitungszeitraum);
    const fallbeschreibung = useSelector(state => state.fallbeschreibung);
    const haftsache = useSelector(state => state.haftsache);
    const eilsache = useSelector(state => state.eilsache);
    const name = useSelector(state => state.name);
    const funktion = useSelector(state => state.funktion);
    const mail = useSelector(state => state.mail);
    const telefon = useSelector(state => state.telefon);

    const formData = new FormData();

    useEffect(() => {
        formData.append('Fragestellung', fragestellung);
        formData.append('Auftraggeber', auftraggeber);
        formData.append('Aktenzeichen', aktenzeichen);
        formData.append('Bearbeitungszeitraum', bearbeitungszeitraum);
        formData.append('Fallbeschreibung', fallbeschreibung);
        formData.append('Haftsache', haftsache);
        formData.append('Eilsache', eilsache);
        formData.append('Name', name);
        formData.append('Funktion', funktion);
        formData.append('Mail', mail);
        formData.append('Telefon', telefon);

        if(fragestellung !== '') {
            axios({
                method: "post",
                url: "https://anfrage.rp-hoffmann.de/formvalidation.php",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
              })
                .then(function (response) {
                  //handle success
                  console.log(response);
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });
        }else {
            navigate('/');
        }

    }, []);
    return (
        <Layout>
            <SidebarWrapper>
                <Sidebar step={5} />
            </SidebarWrapper>
            <FormWrapper frage={'Anfrage versendet'}>
                <p className="mb-4">Vielen Dank für Ihre Anfrage, ich werde mich in Kürze mit Ihnen in Verbindung setzen.</p>
                <p className="mb-4">Mit freundlichen Grüßen,<br/>Isabelle Hoffmen<br/>Psychologin M.Sc.</p>
                <a
                    href="https://rp-hoffmann.de/"
                    className="text-red hover:text-red-hover transition-all"
                >
                    Zurück zur Homepage
                </a>
            </FormWrapper>
        </Layout>
    )
}

export default VielenDank