
const Layout = ({ children }) => {
    return (
        <div className="min-h-screen lg:min-h-0 flex flex-col justify-between lg:grid lg:grid-cols-4 xl:grid-cols-5 px-4 sm:px-6 xl:px-0">
            {children}
            <div className="h-10 flex lg:hidden items-center justify-center gap-x-4 mt-auto lg:mt-0">
                <a
                    href="https://rp-hoffmann.de/impressum"
                    target="_blank"
                    className="text-sm text-neutral-400 hover:text-red transition-all"
                >
                    Impressum
                </a>
                <a
                    href="https://rp-hoffmann.de/datenschutz"
                    target="_blank"
                    className="text-sm text-neutral-400 hover:text-red transition-all"
                >
                    Datenschutz
                </a>
            </div>
        </div>
    )
}

export default Layout