import { createSlice } from '@reduxjs/toolkit';

const rootSlice = createSlice({
    name: "root",
    initialState: {
        fragestellung: "",
        auftraggeber: "",
        aktenzeichen: "",
        name: "",
        funktion: "",
        fallbeschreibung: "",
        bearbeitungszeitraum: "",
        telefon: "",
        mail: "",
        haftsache: "",
        eilsache: ""
    },
    reducers: {
        chooseFragestellung: (state, action) => { state.fragestellung = action.payload },
        chooseAuftraggeber: (state, action) => { state.auftraggeber = action.payload },
        chooseAktenzeichen: (state, action) => { state.aktenzeichen = action.payload },
        chooseName: (state, action) => { state.name = action.payload },
        chooseFunktion: (state, action) => { state.funktion = action.payload },
        chooseFallbeschreibung: (state, action) => { state.fallbeschreibung = action.payload },
        chooseBearbeitungszeitraum: (state, action) => { state.bearbeitungszeitraum = action.payload },
        chooseTelefon: (state, action) => { state.telefon = action.payload },
        chooseMail: (state, action) => { state.mail = action.payload },
        chooseHaftsache: (state, action) => { state.haftsache = action.payload },
        chooseEilsache: (state, action) => { state.eilsache = action.payload }
    }
})

export const reducer = rootSlice.reducer;

export const { chooseFragestellung, chooseAuftraggeber, chooseAktenzeichen, chooseName, chooseFunktion, chooseFallbeschreibung, chooseBearbeitungszeitraum, chooseTelefon, chooseMail, chooseHaftsache, chooseEilsache } = rootSlice.actions;